<template>
  <v-row class="ma-0">
    <v-col
      cols="12"
      lg="6"
      xl="6"
      class="primary d-none d-md-flex align-center justify-center"
    >
      <v-container class="pa-0">
        <div class="pa-0">
          <v-row justify="center">
            <img src="/img/gerb3.png" style="opacity: 0.5" />
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="6" xl="6" class="d-flex align-center">
      <v-container class="pa-0" style="margin-top: -130px"
        ><center>
          <h3 class="grey--text">
            Инновационная платформа обучения <br />
            личного состава подразделений пожарной охраны
          </h3>
          <br />
          <h1
            class="font-weight-thin blue--text text--lighten-2"
            style="
              font-weight: 900 !important;
              font-size: 40px;
              display: inline;
              margin-top: 5px;
            "
          >
            Подготовка
          </h1>
        </center>
        <div class="">
          <center>
            <v-col cols="12" lg="9" xl="6">
              <!---<h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Войти</h2>-->
              <h6 class="subtitle-1">
                Нет аккаунта?
                <router-link to="/authentication/register"
                  >Зарегистрируйтесь!</router-link
                >
              </h6>
              <br />
              <!---Данные для входа<br>
                            Логин: test@eios-mchs.ru<br>
                            Пароль: Test12345<br>-->

              <v-form
                ref="form"
                v-model="valid"
                action="#"
                @keyup.enter="login()"
                v-on:submit.prevent="login()"
                lazy-validation
              >
                <v-text-field
                  v-model="data.body.username"
                  :rules="emailRules"
                  label="E-mail"
                  placeholder="E-mail"
                  class="mt-4"
                  required
                  persistent-placeholder
                ></v-text-field>
                <v-text-field
                  v-model="data.body.password"
                  :rules="passwordRules"
                  label="Пароль"
                  required
                  persistent-placeholder
                  placeholder="Пароль"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                ></v-text-field>
                <div class="red--text" v-show="error">{{ error }}</div>
                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                  <v-checkbox
                    v-model="data.body.rememberMe"
                    label="Запомнить меня?"
                    required
                  ></v-checkbox>
                  <div class="ml-auto">
                    <router-link to="/authentication/recovery" class="link"
                      >Забыли пароль?</router-link
                    >
                  </div>
                </div>
                <v-btn
                  :loading="logging"
                  :disabled="!valid"
                  color="info"
                  block
                  class="mr-4"
                  submit
                  v-on:click="login()"
                  >Войти</v-btn
                ><br />
                <!---<v-btn @click="testLogin()" :loading="logging"
                  >Войти под тестовым пользователем</v-btn
                ><br />
                <v-btn
                  @click="promo = true"
                  :disabled="logging"
                  color="primary"
                  class="ma-3"
                  >Видеопрезентация</v-btn
                >-->
                <!---<v-dialog v-model="promo" max-width="1024px" max-height="800px">
                  <video-player
                    class="video-player-box"
                    ref="videoPlayer"
                    :options="{
                      muted: false,
                      language: 'ru',
                      sources: [
                        {
                          type: 'video/mp4',
                          src: 'https://api.eios-mchs.ru/uploads/contest/promo.mp4',
                        },
                      ],
                      coding: false,
                      height: '800px',
                    }"
                    :playsinline="true"
                    style="width: 100%"
                  >
                  </video-player>
                </v-dialog>-->
              </v-form>
              <!---<div class="text-center mt-6" v-if="1 !== 1">
                <v-chip pill class="mr-2">
                  <v-avatar left>
                    <v-btn color="blue lighten-1" class="white--text">
                      <v-icon>mdi-twitter</v-icon>
                    </v-btn> </v-avatar
                  >Twitter
                </v-chip>
                <v-chip pill>
                  <v-avatar left>
                    <v-btn color="teal lighten-2" class="white--text">
                      <v-icon>mdi-github</v-icon>
                    </v-btn> </v-avatar
                  >Github
                </v-chip>
              </div>-->
            </v-col>
          </center>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<style>
.vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-left: -45px;
  margin-top: -22px;
}
.video-js {
  width: 100%;
}
</style>
<script>
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
//import { videoPlayer } from "vue-video-player";
export default {
  name: "FullLogin",
  components: {
    //videoPlayer,
  },
  data: () => ({
    promo: false,
    valid: true,
    password: "",
    show1: false,
    passwordRules: [
      (v) => !!v || "Нужно ввести пароль",
      (v) => (v && v.length >= 6) || "Пароль должен содержать более 6 символов",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail должен быть заполнен",
      (v) => /.+@.+\..+/.test(v) || "E-mail введен неверно",
    ],
    checkbox: false,
    logging: false,
    data: {
      body: {
        username: "",
        password: "",
        rememberMe: false,
      },
      rememberMe: false,
      fetchUser: true,
    },
    error: null,
  }),
  methods: {
    testLogin() {
      this.data.body.password = "Test12345";
      this.data.body.username = "test@eios-mchs.ru";
      this.$nextTick(() => {
        this.login();
      });
    },
    login() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.logging = 0;
        this.timer = setInterval(() => {
          if (this.logging < 100) {
            this.logging += 10;
          }
        }, 10);
        this.$auth
          .login({
            // body: this.data.body, // Vue-resource
            data: this.data.body, // Axios
            rememberMe: this.data.body.rememberMe,
            fetchUser: true,
            staySignedIn: this.data.body.rememberMe,
            redirect: "/",
          })
          .then(() => {
            this.$parent.$parent.$parent.$parent.$refs.loader.play();
            clearInterval(this.timer);
            this.logging = false;
          })
          .catch((e) => {
            this.error = e;
            clearInterval(this.timer);
            this.logging = false;
          });
      }
    },
  },
};
</script>
